.messenger {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0px 20px;

  .title {
    font-size: 56px;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .list-chat {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .card {
    border-radius: 32px;
    border: 1px solid #000;
    padding: 24px;
    background: #fff;

    &.faded {
      background: transparent;
    }

    .box-item {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .chat-info {
        display: grid;
        grid-template-columns: 48px 1fr;
        gap: 10px;
        width: 100%;
        align-items: start;

        .icon {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          width: 48px;
          height: 48px;
        }

        .title {
          display: flex;
          justify-content: space-between;
          width: 100%;

          .date {
            color: #808080;
            font-size: 13px;
            font-weight: 400;
          }
        }

        .address {
          max-width: 200px;
          display: block;
          color: #808080;
          font-size: 13px;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 5px;

          svg {
            path {
              stroke: #ffac39;
            }
          }
        }
      }

      .item {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        .title {
          color: #1d2023;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.45px;
        }
      }

      .last-message {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
