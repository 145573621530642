.profile {
  padding: 12px 20px;

  .gradient-border {
    display: inline-block;
    padding: 3px; /* Ajuste conforme necessário */
    background: linear-gradient(90deg, #1f3fff, #ff065c 49.5%, #ffac39);
    border-radius: 50%;
    box-sizing: border-box;
  }

  .general-sub-title {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-top: 12px;
  }

  .content {
    border-radius: 32px;
    border: 1px solid #000;
    padding: 8px 20px;
    background: #fff;

    .box-item {
      display: flex;
      gap: 20px;

      .icon {
        align-items: center;
        display: flex;
        width: 30px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .item {
        cursor: pointer;
        margin: 18px 0;
        display: flex;
        flex-direction: column;
        .title {
          color: #1d2023;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.45px;
        }
      }
    }
  }
  .rating {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 24px;
    border: 1px solid #1d2023;
  }
  .danger-zone {
    margin-top: 20px;
    border: 1px solid #f8514966;
    border-radius: 16px;
    padding: 12px;
    p {
      margin: 4px 0;
    }
  }
}

.social-media {
  .content {
    margin-top: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .media {
      display: flex;
      gap: 20px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.coverage {
  .location {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #3c3c435c;
    .item {
      color: #1d2023;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.45px;
    }
  }
  .location-search {
    display: flex;
    gap: 12px;
  }
  .location-add {
    cursor: pointer;
    display: flex;
    border-bottom: 1px solid #3c3c435c;
    color: #1d2023;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.45px;
    padding: 20px 0;
  }
  .add-location {
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    gap: 12px;
    color: #1f3fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.32px;
  }
}

.categories {
  .category {
    cursor: pointer;
    padding: 6px 16px;
    border-radius: 20px;
    border: 1px solid #1d2023;
    font-family: Inter;
    line-height: 22px;
    letter-spacing: -0.32px;
    .item {
      display: flex;
      gap: 12px;
      font-size: 16px;
    }
    &.selected {
      background: #e2f2dc;
      .item {
        font-family: Poppins;
        // font-size: 22px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.41px;
      }
    }
  }
}

.notifications {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .checkbox-container {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: Inter;
    font-size: 18px;
  }
  .sub-title {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-top: 12px;
  }

  .value-field {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
    font-weight: 600;
  }
}

.payment-settings {
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
}

.personal-info {
  .edit-overlay {
    cursor: pointer;
    position: relative;
    display: inline-block;
    .edit-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: #fff;

      opacity: 0;
      transition: opacity 0.3s ease;
    }
    &:hover {
      .edit-text {
        opacity: 1;
      }
      img {
        filter: blur(4px);
      }
    }
  }
}

.page-selection {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .general-sub-title {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin-top: 12px;
  }

  .box-social {
    cursor: pointer;
    padding: 12px;
    border-radius: 10px;
    // border: 1px solid #a5a6a7;
    box-shadow: 4px 4px 8px 4px #a5a6a740;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
    width: calc(100% - 36px);
    .title {
      font-family: Inter;
      font-size: 14px;
      &.bold {
        font-weight: 700;
      }
    }
    .content-social {
      display: flex;
      gap: 6px;
      align-items: center;
    }
    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      border: 0.2px solid #a5a6a7;
    }
  }
}
