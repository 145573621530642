.caption-regular {
  color: var(--Base-Black, #1d2023);
  text-align: center;
  /* Caption/13 Regular */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.078px;
}

.heading-1-32-bold {
  color: var(--Label-Color-Light-Primary, #000);
  /* Heading 1/32 Bold */
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px; /* 131.25% */
  letter-spacing: 0.38px;
}

.heading-2-26-bold {
  color: var(--Label-Color-Light-Primary, #000);
  text-align: center;
  /* Heading 2/26 Bold */
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 115.385% */
  letter-spacing: 0.364px;
}

.heading-3-22-semi-bold {
  color: var(--Label-Color-Light-Primary, #000);
  text-align: center;
  /* Heading 3/22 SemiBold */
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 118.182% */
  letter-spacing: -0.41px;
}

.body-16-regular {
  color: var(--Base-Black, #1d2023);
  /* Body/16 Regular Inter */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.body-16-regular-poppins {
  color: var(--Base-Black, #1d2023);
  /* Body/16 Regular Poppins */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.body-16-semi-bold-poppins {
  color: var(--Base-Black, #1d2023);
  /* Body/16 Regular Poppins */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.body-16-semi-bold-inter {
  color: var(--Base-Black, #1d2023);
  text-align: center;
  /* Body/16 Semibold Inter */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.subtitle-18-semi-bold-inter {
  color: var(--Base-Black, #1d2023);
  /* Subtitle/18 Semibold Inter */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.45px;
}

.subtitle-18-regular {
  color: var(--Base-Black, #1d2023);
  text-align: center;
  /* Subtitle/18 Regular Inter */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.45px;
}

.subtitle-18-regular-poppins {
  color: var(--Base-Black, #1d2023);
  /* Subtitle/18 Semibold Poppins */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.45px;
}

.caption-13-regular {
  color: var(--Base-Black, #1d2023);
  text-align: center;
  /* Caption/13 Regular */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.078px;
}
