.login {
  .container {
    padding: 40px;
    margin: 20px;
    border-radius: 20px;
    border: 2px solid #000;
    background: #fff;
    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
    }
  }
}
