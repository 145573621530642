.navigation-bar {
  height: 49px;
  padding: 12px;
  display: flex;
  gap: 6px;
  border-bottom: 1px solid var(--Gray-03, #c1c1c1);
  margin-bottom: 20px;
  &.hidden {
    border: none;
  }
  .back-button {
    display: flex;
    align-items: center;
    border-radius: 100px;
    background: var(--BG-Beige-Dark, #ede8dd);
    padding: 4px;

    svg {
      cursor: pointer;
      height: 16px;
      width: 16px;
    }
  }
}
