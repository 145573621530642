@import "src/theme/Typography.scss";

.collabs {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .ant-tag {
    font-family: Inter;
    &.ghost-kitchen {
      border-radius: 8px;
    }
  }

  .notification-history-box {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
    margin: 12px 20px;
    flex: 1;
    span {
      color: blue;
      cursor: pointer;
    }
    svg {
      cursor: pointer;
    }
  }
  .empty-schedule {
    display: flex;
    margin: 32px 20px;
    h1 {
      font-size: 36px;
      font-weight: 700;
      line-height: 40px; /* 111.111% */
      letter-spacing: 0.38px;
      max-width: 260px;
      margin: 0;
    }
    .image-container {
      flex: 1;
      text-align: end;
    }
  }
  .upcoming {
    border-radius: 32px 32px 0 0;
    background: #e2f2dc;
    flex: 1;
    padding: 20px;
    .no-items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 50px;
      .title {
        display: block;
        margin-top: 24px;
        color: var(--Base-Black, #1d2023);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.45px;
      }
      .sub-title {
        color: var(--Gray-01, #666);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.078px;
      }
    }
    .upcoming-collab {
      .left-side {
        width: 65px;
      }
      .collab-content {
        flex: 1;
        padding: 20px;
        border-radius: 24px;
        border: 1px solid #000;
        background: #fff;
      }
      .box-perk {
        display: flex;
        border-radius: 8px;
        background: #e2f2dc;
        padding: 6px;
        gap: 4px;
        flex: 1;
        justify-content: center;
        align-items: center;
        .value {
          span {
            display: block;
          }
        }
      }
    }
  }

  .pending {
    border-radius: 32px 32px 0 0;
    background: #e9ecff;
    flex: 1;
    padding: 20px;

    .pending-collab {
      .left-side {
        width: 65px;
      }
      .collab-content {
        flex: 1;
        padding: 20px;
        border-radius: 24px;
        border: 1px solid #000;
        background: #fff;
      }
      .box-perk {
        display: flex;
        border-radius: 8px;
        background: #f5f5f5;
        padding: 6px;
        gap: 4px;
        flex: 1;
        justify-content: center;
        align-items: center;
        .value {
          span {
            display: block;
          }
        }
      }
    }
    .applicants {
      color: #ff5c00;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0;
    h3 {
      margin: 0;
    }
    svg {
      cursor: pointer;
    }
  }

  .content {
    padding: 20px;
    .color-black {
      path,
      rect {
        stroke: #1d2023;
      }
    }
    .highlight {
      position: relative;
      padding: 24px 18px;
      border-radius: 32px;
      border: 1px solid var(--Base-Black, #1d2023);
      background: var(--BG-Beige-Light, #f9f4e9);

      .sticker {
        position: absolute;
        right: -22px;
        top: -22px;
      }

      .title {
        margin: 0 0 16px 0;
        color: var(--Label-Color-Light-Primary, #000);
        /* Heading XL/36 Bold */
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px; /* 111.111% */
        letter-spacing: 0.38px;
      }

      .venue {
        color: var(--Base-Black, #1d2023);
        /* Subtitle/18 Semibold Poppins */
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.45px;
      }

      .box-perk {
        display: flex;
        padding: 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        background: var(--BG-Green-Light, #e2f2dc);
        span {
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
        }
      }
    }

    .tomorrow {
      .box-perk {
        display: flex;
        border-radius: 8px;
        background: #e2f2dc;
        padding: 6px;
        gap: 4px;
        flex: 1;
        justify-content: center;
        align-items: center;
        .value {
          span {
            display: block;
          }
        }
      }
    }
    .content-due {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .collab-content {
        flex: 1;
        padding: 20px;
        border-radius: 24px;
        border: 1px solid #000;
        background: #fff;
      }
      .row {
        margin: 6px 0;
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .right-side {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .get-cash {
          color: var(--Base-Black, #1d2023);
          text-align: right;
          font-family: Inter;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 123.077% */
          letter-spacing: -0.078px;
          b {
            color: var(--Base-Black, #1d2023);
            text-align: right;
            /* Heading 3/22 SemiBold */
            font-family: Poppins;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px; /* 118.182% */
            letter-spacing: -0.41px;
          }
        }
        .effect-rating {
          color: var(--Base-Black, #1d2023);
          text-align: right;
          font-family: Inter;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 123.077% */
          letter-spacing: -0.078px;
        }
      }
    }
  }
  .emoji-size {
    font-size: 24px;
  }

  .line {
    background: #c1c1c1;
    height: 1px;
    flex: 1;
  }
}

.history,
.notifications {
  width: 100%;
  .header {
    margin: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    h3 {
      margin: 0;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      display: block;
      margin-top: 24px;
      color: var(--Base-Black, #1d2023);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.45px;
    }
    .sub-title {
      color: var(--Gray-01, #666);
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 138.462% */
      letter-spacing: -0.078px;
    }
  }
  .content-due {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    .collab-content {
      flex: 1;
      padding: 20px;
      border-radius: 24px;
      border: 1px solid #000;
      background: #fff;

      .row {
        margin: 6px 0;
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .general-sub-title {
        color: var(--Gray-01, #666);
        /* Caption/13 Regular */
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.078px;
      }
    }
    .box-perk {
      display: flex;
      border-radius: 8px;
      background: #e2f2dc;
      padding: 6px;
      gap: 4px;
      flex: 1;
      justify-content: center;
      align-items: center;
      .value {
        span {
          display: block;
        }
      }
    }
  }
}

.collab-detail {
  .button-with-content {
    flex: 1;
    height: 60px;
    display: block;
    .main {
      display: block;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .week-list {
    display: flex;
    gap: 3px;
    .day {
      border-radius: 19.658px;
      border: 1px solid #c1c1c1;
      color: #c1c1c1;
      width: 19px;
      height: 19px;
      text-align: center;
      &.open {
        border-color: #1d2023;
        color: #1d2023;
      }
    }
  }

  .briefing {
    margin-top: 40px;
    margin-bottom: 80px;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 32px;
    background: var(--BG-Green-Light, #e2f2dc);
    color: #1d2023;
    .title {
      font-size: 16px;
      font-weight: 600;
    }
    ul {
      line-height: 1.5em;
      margin: 8px 0 0;
      padding: 0 24px;
      padding-inline-start: 0;
    }

    li {
      list-style: none;
      position: relative;
      padding: 0 0 8px 30px;
      span {
        strong {
          color: #7b79ff;
        }
      }
    }

    li {
      &.circle {
        &.checkmark {
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: solid 1px;
            border-color: #06ca60;
          }
          &::after {
            content: "";
            position: absolute;
            left: 8px;
            top: 4px;
            width: 6px;
            height: 9px;
            border: solid;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
            border-color: #06ca60;
          }
        }
        &.x-mark {
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: solid 1px;
            border-color: #1d2023;
          }
          &::after {
            content: "x";
            position: absolute;
            left: 7px;
            top: 0;
            width: 6px;
            height: 9px;
            font-size: 16px;
            font-weight: 300;
            color: #1d2023;
          }
        }
      }
    }
  }

  .box-value {
    border-radius: 12px;
    background: #fff;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #1d2023;
    .key {
      font-size: 26px;
      font-weight: 700;
    }
    .value {
      font-size: 13px;
      font-weight: 400;
    }
  }

  .blue-box {
    border-radius: 8px;
    border: 1px solid rgba(31, 63, 255, 0.3);
    background: #e5ebff;
    padding: 16px;
    font-family: Inter;
    font-size: 16px;
  }

  .box-perk {
    display: flex;
    border-radius: 8px;
    background: #ede8dd;
    padding: 6px;
    gap: 4px;
    flex: 1;
    justify-content: center;
    align-items: center;
    .value {
      span {
        display: block;
      }
    }
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sort-popover {
  .ant-popover-content {
    .ant-popover-inner {
      padding: 0;
      ul {
        padding: 0;
        list-style: none;
        width: 200px;
        li {
          padding: 12px 20px;
          cursor: pointer;
          &:hover {
            color: #ff5c00;
          }
          &:first-child {
            border-bottom: 1px solid #999;
          }
          .selected {
            color: #ff5c00;
            display: flex;
            justify-content: space-between;
            svg {
              path {
                stroke: #ff5c00;
              }
            }
          }
        }
      }
    }
  }
}

.drawer-rating {
  .collabs-drawer-header {
    .ant-drawer-header-title {
      flex-direction: row-reverse;
    }
  }
  .ant-drawer-content-wrapper {
    box-shadow: none;
    .ant-drawer-content {
      background-color: #f9f4e9;
      border-radius: 32px 32px 0px 0px;
    }
    .ant-drawer-header-title {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .ant-drawer-title {
        @extend .heading-3-22-semi-bold;
        text-align: left;
        margin-right: 12px;
      }
    }
    .collabs-drawer-body {
      .rating-percentage {
        color: #000;
        display: block;
        text-align: center;
        font-family: Poppins;
        font-size: 56px;
        font-style: normal;
        font-weight: 700;
        line-height: 41px; /* 73.214% */
        letter-spacing: 0.374px;
      }

      .reputation-label {
        margin-top: 12px;
        display: block;
        text-align: center;
        color: #009546;

        /* Body/16 Regular Inter */
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        letter-spacing: -0.32px;
      }

      .disclaimer {
        display: block;
        color: var(--Gray-01, #666);
        text-align: center;
        /* Caption/13 Regular */
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.078px;
      }

      .content {
        display: flex;
        gap: 6px;
        .sub-title {
          color: var(--Base-Black, #1d2023);
          /* Subtitle/18 Semibold Poppins */
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 133.333% */
          letter-spacing: -0.45px;
        }
        .box-detail {
          border-radius: 8px;
          display: flex;
          justify-content: center;
          width: 84px;
          height: 64px;
          padding: 8px 0px;
          flex-direction: column;
          align-items: center;
          gap: 2px;
          flex-shrink: 0;
          &.green {
            background: var(--BG-Green-Light, #e2f2dc);
          }
          &.red {
            background: #ff354a1a;
          }

          .value {
            color: var(--Base-Black, #1d2023);
            text-align: right;
            /* Subtitle/18 Semibold Inter */
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px; /* 133.333% */
            letter-spacing: -0.45px;
          }

          .label {
            color: var(--Base-Black, #1d2023);
            /* XS/11 Regular */
            font-family: Inter;
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 145.455% */
            letter-spacing: 0.066px;
          }
        }
      }
    }
  }
}
.rating-box {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 12px 0;
  .rating {
    display: flex;
    padding: 2px 12px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 24px;
    border: 1px solid var(--Base-Black, #1d2023);
  }
}
