.confirm {
  .briefing {
    margin-bottom: 80px;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 32px;
    background: var(--BG-Green-Light, #e2f2dc);
    color: #1d2023;
    .title {
      font-size: 16px;
      font-weight: 600;
      display: block;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 600;
      display: block;
      margin-top: 12px;
    }

    ul {
      line-height: 1.5em;
      margin: 8px 0 0;
      padding: 0 24px;
      padding-inline-start: 0;
    }

    li {
      list-style: none;
      position: relative;
      padding: 0 0 8px 30px;
      color: #666666;
      span {
        strong {
          color: #7b79ff;
        }
      }
    }

    li {
      &.circle {
        &.checkmark {
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: solid 1px;
            border-color: #666666;
          }
          &::after {
            content: "";
            position: absolute;
            left: 8px;
            top: 4px;
            width: 6px;
            height: 9px;
            border: solid;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
            border-color: #666666;
          }
        }
      }
    }
  }

  .confirm-button {
    margin-bottom: 20px;
  }

  .floating-confirm-button {
    margin-bottom: 60px;
  }
}
