.address-deliver {
  .ant-select-single {
    .ant-select-selector {
      .ant-select-selection-placeholder,
      .ant-select-selection-item {
        text-align: start;
      }
    }
  }
}
