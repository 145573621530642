.book-detail {
  .slot-info {
    display: block;
    text-align: end;
    font-weight: 600;
  }
  .time-slot-group {
    gap: 8px 8px;
    .time-slot {
      width: 100%;
      padding: 12px;
      border-radius: 8px;
      border: 1px solid var(--Gray-03, #c1c1c1);
      background: var(--Base-White, #fff);
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #1d2023;
      cursor: pointer;
      &.selected {
        outline: 2px solid var(--Base-Black, #1d2023);
      }
    }
  }

  .footer-collab {
    padding: 8px 12px 20px 12px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    z-index: 1;
    height: 86px;
    display: none;
    justify-content: space-between;
    align-items: center;
    span {
      color: #666;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    button {
      width: 200px;
    }

    &.show {
      display: flex;

      @keyframes slide-up {
        0% {
          transform: translateY(100%);
          opacity: 0;
        }
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }

      // animation: slide-up 0.3s ease;
    }
  }

  .tag {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.32px;
    border-radius: 8px;
    color: #1d2023;
    padding: 2px 8px;
    &.gray {
      background: #1f3fff1a;
    }
    &.green {
      background-color: #e2f2dc;
    }
    &.error {
      color: #ff354a;
      background: rgba(255, 53, 74, 0.1);
    }
  }

  .book-preferred-dates {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 12px;
    background-color: #e9ecff;
    border-radius: 16px;
    padding: 12px;
    .title {
      font-weight: 400;
    }
    .book-date {
      margin-bottom: 10px;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      &.selected {
        span {
          background-color: #ede8dd;
          border: 1px solid #1d2023;
          border-radius: 40px;
          padding: 4px 12px;
        }
      }
    }
  }
}

.collab-detail {
  .copiable-info {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    color: var(--Base-Blue, #1f3fff);
    /* Body/16 Regular Inter */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.32px;
  }
}

.book-confirmation {
  height: 100%;
  overflow: hidden;
  position: fixed;
  width: 398px;
  padding: 12px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &.green {
    background-color: #e2f2dc;
  }
  &.red {
    background-color: #ffebed;
  }

  .buttons {
    text-align: center;
    button {
      width: 100%;
      margin-top: 8px;
    }
  }
}

.bigger-svg {
  svg {
    width: 24px;
    height: 24px;
  }
}

.regular-menu-item {
  .ant-dropdown-menu-title-content {
    color: #1d2023;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.45px;
  }
}
.danger-menu-item {
  .ant-dropdown-menu-title-content {
    color: #ff354a;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.45px;
  }
}
