.certificate {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px;
  .card {
    width: 350px;
    background-color: #ffffff;
    position: relative;
    border-radius: 15px;
    // overflow: hidden;
    padding-top: 70px; /* Espaço para o efeito no topo */
  }

  .card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 2px);
    height: 70px;
    background-color: #d8f6c8;
    border-top: 1px dashed #000;
    border-left: 1px dashed #000;
    border-right: 1px dashed #000;
    border-radius: 15px 15px 0 0;
    z-index: 1;
  }

  .card::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 20px;
    background-color: #e2f2dc;
    border-bottom: 1px dashed #000;
    border-left: 1px dashed #000;
    border-right: 1px dashed #000;
    border-radius: 0 0 20px 20px;
    z-index: 2;
  }

  .logo {
    position: absolute;
    z-index: 5;
    left: 50%;
    transform: translate(-50%);
    top: 40px;
  }

  .card-body {
    padding: 20px;
    text-align: center;
    border-left: 1px dashed #000;
    border-right: 1px dashed #000;
    // background-color: #ffffff;
    border-bottom: 1px dashed #000;
    border-left: 1px dashed #000;
    border-right: 1px dashed #000;
    border-radius: 0 0 15px 15px;
  }

  .card-id {
    font-size: 24px;
    font-weight: bold;
  }

  .card-name {
    font-size: 18px;
    font-weight: bold;
    margin: 8px 0 24px 0;
  }

  .box-perk {
    display: flex;
    border-radius: 8px;
    background: #e2f2dc;
    padding: 6px;
    gap: 4px;
    flex: 1;
    justify-content: center;
    align-items: center;
    .value {
      span {
        display: block;
      }
    }
  }

  .card-footer {
    padding: 24px 20px 0 20px;
    .gratuity {
      color: #ff5c00;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.32px;
    }
  }
}
