.find-collabs {
  position: relative;
  overflow: hidden;
  width: 100%;
  .maps-container {
    background-color: gray;
    transition: height 0.2s ease; // Add transition property
    width: 100%;
  }
  .card-collabs {
    margin-top: -30px;
    position: relative;
    padding: 30px 14px;
    border-radius: 32px 32px 0px 0px;
    background: #f9f4e9;
    height: 68%; //50vh; // calc(100vh - 86px - 370px);
    overflow: auto;
    padding-bottom: 100px;

    .collabs {
      overflow: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      .collab {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-radius: 24px;
        border: 1px solid #000;
        padding: 14px;
        background: #fff;
        &.selected {
          border-color: #ff5c00;
        }
        .highlight {
          color: #1d2023;

          font-weight: 600;
          font-size: 18px;
        }
      }

      .ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .applicants {
      color: #ff5c00;
    }

    .box-perk {
      display: flex;
      border-radius: 8px;
      background: #e2f2dc;
      padding: 6px;
      gap: 4px;
      .value {
        span {
          display: block;
        }
      }
    }
  }

  .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 30px;
    border: 1px solid var(--Base-Black, #1d2023);
    background: var(--Base-White, #fff);

    font-size: 18px;
    font-weight: 600;
    color: #1d2023;
    .ant-select-selection-search-input {
      font-size: 16px;
    }
  }
}
