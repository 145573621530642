@import "./Typography.scss";
@import "./Grid.scss";
@import "./Button.scss";

.layout {
  // padding-top: 14px;
  min-height: 100vh;
  // max-width: 400px;
  .ant-layout-content {
    overflow: hidden;
  }
  .footer {
    width: 100%;
    display: flex;
    justify-content: space-around;
    border-top: 0.5px solid rgba(60, 60, 67, 0.36);
    background: rgba(249, 249, 249, 0.94);
    backdrop-filter: blur(10px);
    padding: 8px 12px 20px 12px;
    position: fixed;
    bottom: 0;
    .menu {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      cursor: pointer;
      filter: grayscale(100%);
      &.selected {
        filter: grayscale(0%);
        color: #ff5c00;
      }

      svg {
        height: 23px;
      }
    }
  }

  button {
    &.ant-btn.ant-btn-default.ant-btn-color-dangerous.ant-btn-variant-outlined {
      background-color: transparent;
      font-weight: 600;
    }
  }

  .ant-picker-calendar {
    .calendar-title {
      color: #1d2023;
      font-size: 18px;
      font-weight: 600;
      margin-left: 14px;
    }

    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        &.ant-picker-calendar-date {
          background-color: #e2f2dc;
          color: #1d2023;
          border-radius: 30px;
          outline: 1px solid #1d2023;
          &::before {
            border-radius: 30px;
          }
        }
      }
    }

    .ant-picker-cell-inner {
      &.ant-picker-calendar-date {
        &.ant-picker-calendar-date-today {
          // background-color: #e2f2dc;
          // color: #1d2023;
          border-radius: 30px;
          &::before {
            border-radius: 30px;
            // border-color: #1d2023;
            border: 0;
          }
        }
      }
    }

    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
        .ant-picker-cell-range-start
      ):not(.ant-picker-cell-range-end)
      .ant-picker-cell-inner {
      border-radius: 30px;
    }
    .ant-picker-cell-disabled {
      &::before {
        background-color: transparent;
      }
    }
  }

  .page-full-screen {
    &.fixed {
      position: fixed;
    }
    height: 100%;
    overflow: hidden;
    width: 398px;
    padding: 12px;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #f9f4e9;
    &.green {
      background-color: #e2f2dc;
    }
    &.red {
      background-color: #ffebed;
    }

    .buttons {
      text-align: center;
      button {
        width: 100%;
        margin-top: 8px;
      }
    }
  }

  .page-full-height {
    height: 100dvh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #f9f4e9;
    &.green {
      background-color: #e2f2dc;
    }
    &.red {
      background-color: #ffebed;
    }
  }
}

.ant-select {
  .ant-select-selector {
    .ant-select-selection-search-input {
      font-size: 16px;
    }
  }
}
