.action-message {
  &__container {
    display: flex;
    word-break: break-word;
    gap: 10px;
    margin-bottom: 10px;
    flex-direction: row;
    margin-right: 2px;
  }

  &__actions-container {
    padding-top: 10px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__timestamp {
    font-size: 12px;
    color: #999999;
    padding-left: 50px;
  }

  &__confirmation-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    z-index: 10;
  }

  &__confirmation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    text-align: center;
    padding: 20px;

    &-text {
      font-size: 14px;
      color: #1d2023;
    }

    &-buttons {
      display: flex;
      gap: 8px;
    }
  }
}
