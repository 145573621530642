.apple-auth-button {
  background-color: #000;
  color: #fff;
  border-radius: 100px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  padding: 4px 20px;
  border: 0;

  svg {
    height: 36px;
    width: 20px;
  }
}
