.black-button {
  background-color: black;
  color: white;
  padding: 15px 20px;
  border-radius: 33px;
  border: none;
  cursor: pointer;
  width: 100%;
}

.button-facebook-color {
  border-radius: 30px;
  border: 1px solid var(--Base-Black, #1d2023);
  background: #4466b4;
  color: white;
  padding: 15px 20px;
  line-height: 12px;
}
