.chat-page {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0px 20px;

  .card {
    border-radius: 32px;
    border: 1px solid #000;
    padding: 8px 20px;
    background: #fff;

    .box-item {
      display: flex;
      gap: 20px;

      &.faded {
        & .icon {
          opacity: 0.5;
        }

        & .item {
          & .title {
            color: #999999;
            font-weight: 500;
          }
        }
      }

      .icon {
        align-items: center;
        display: flex;
        width: 30px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
      .item {
        cursor: pointer;
        margin: 18px 0;
        display: flex;
        flex-direction: column;
        .title {
          color: #1d2023;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.45px;
        }
      }
    }
  }

  &.chat {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    height: 100vh;

    .header {
      display: grid;
      grid-template-columns: 30px 1fr;
      background: #f9f4e9;
      height: 80px;
      align-items: center;
      padding: 0 15px;

      .title-container {
        display: flex;
        align-items: center;
        gap: 10px;
        white-space: nowrap;

        .avatar-container {
          position: relative;
        }

        .info-container {
          display: flex;
          flex-direction: column;
          gap: 3px;

          .title {
            font-size: 13px;
            font-weight: 600;
          }

          .date {
            font-size: 13px;
            font-weight: 300;
            color: #1d2023;
          }

          .address {
            font-size: 13px;
            font-weight: 300;
            color: #1d2023;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 80%;

            svg {
              path {
                stroke: #ffac39;
              }
            }
          }
        }
      }
    }

    .content-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: calc(100% - 100px);
      overflow: hidden;

      .messages-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        gap: 10px;
        padding: 12px 12px 0 12px;

        .message {
          background-color: #fff;
          display: flex;
          flex-direction: column;
          border-radius: 20px;
          padding: 6px 18px;
          font-size: 16px;
          border: 1px solid #ececec;
        }
      }

      .input-container {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 12px;
        background-color: #f9f4e9;
        position: sticky;
        bottom: 0;

        .text-area-container {
          display: flex;
          align-items: center;
          position: relative;
          flex: 1;

          .input {
            border: 1px solid #1d2023;
            border-radius: 50px;
            width: 100%;

            padding-right: 100px;
          }

          .send-button-container {
            position: absolute;
            right: 5px;
          }
        }
      }
    }
  }
}
