.slide-button {
  &.container {
    width: 100%;
    height: 60px;
    border: 2px solid #1d2023;
    border-radius: 30px;
    overflow: hidden;
    position: relative;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slider {
    width: 100%;
    height: 100%;
    background-color: #ffac39;
    display: flex;
    align-items: center;
    position: relative;
  }

  .handle {
    height: 100%;
    color: white;
    text-align: center;
    line-height: 50px;
    border-radius: 25px;
    cursor: pointer;
    position: absolute;
  }

  .unlocked {
    // color: #4caf50;
    font-size: 24px;
    background-color: #ffac39;
  }
  .slider-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    .main {
      font-size: 18px;
      font-weight: 600;
    }
  }
}
