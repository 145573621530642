.earnings {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .box-create-wallet {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #e2f2dc;
    padding: 0 20px;
  }
  .header {
    margin-top: 50px;
    padding: 0 20px;
    .label {
      color: #000;

      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.32px;
    }
    .value {
      color: #000;
      display: block;
      margin-top: 12px;
      font-family: Poppins;
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 41px;
      letter-spacing: 0.374px;
    }
  }
  .history {
    border-radius: 32px 32px 0 0;
    background: #e2f2dc;
    flex: 1;
    padding: 20px;
    margin-top: 30px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 12px 0;
      h3 {
        margin: 0;
      }
      svg {
        cursor: pointer;
      }
    }
  }

  .earnings-list {
    padding: 0 20px;
    border-radius: 32px;
    border: 1px solid #000;
    background: #fff;
    position: relative;
    .earning {
      padding: 18px 0;
      .name {
        display: block;
        color: #1d2023;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.45px;
      }
      .value {
        display: block;
        color: var(--Gray-01, #666);
        text-overflow: ellipsis;
        /* Caption/13 Regular */
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 138.462% */
        letter-spacing: -0.078px;
      }
      .amount {
        display: block;
        color: #1d2023;
        text-align: right;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.41px;
        overflow: hidden;
      }
      &:not(:last-child) {
        border-bottom: 1px solid #3c3c435c;
      }
    }
  }
  .earnings-drawer {
    position: absolute;
    bottom: 0;
    &.earning {
      background-color: #e2f2dc;
    }
  }

  .sub-title {
    font-size: 18px;
    font-weight: 600;
    display: block;
    margin: 12px 0;
    text-align: center;
  }

  // &.show {
  //   background-color: #000;
  // }
}

.ant-drawer {
  &.drawer-earning {
    .ant-drawer-content-wrapper {
      display: flex;
      justify-content: center;
      box-shadow: none;
      &.ant-drawer-content-wrapper-hidden {
        display: none;
      }
    }
    .ant-drawer-content {
      border-radius: 30px 30px 0 0;

      max-width: 600px;
    }
    &.withdraw {
      .ant-drawer-content {
        background-color: #f9f4e9;
      }
    }
    &.earning {
      .ant-drawer-content {
        background-color: #e2f2dc;
      }
    }
    .ant-drawer-header {
      border-bottom: none;
      .ant-drawer-header-title {
        flex-direction: row-reverse;
      }
    }
  }
}
