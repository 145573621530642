.welcome-card {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
    background-image: url(../../assets/images/welcome-pattern.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    .ant-avatar-image {
      margin-top: 26px;
      background-color: #fff;
      border: 2.6px solid #ffb400;
    }
  }

  &__logo {
    width: 48px;
    height: 48px;
    margin: 0 auto 8px;
    background-color: #ffb400;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
    color: #fff;
  }

  &__steps-list {
    list-style: none;
    padding: 0;
    margin: 24px 0;
  }

  &__step-item {
    display: flex;
    margin-bottom: 16px;
    gap: 12px;
  }

  &__icon {
    font-size: 20px;
    margin-right: 8px;
    margin-top: 2px; // Alinha com o texto
    color: #333;
  }

  &__step-description {
    margin: 0;
  }

  &__start-button {
    width: 100%;
  }
}
